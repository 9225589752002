<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
          <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-input v-model="searchForm.search" :placeholder="$t('单据编号, 备注')" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" :md="8" :xl="5" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
      </a-col>

      <a-col :span="24" style="width: 480px; margin-bottom: 12px">
        <a-button-group>
          <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
          <a-button icon="file-excel" @click="downloadTemplate">{{ $t("期初库存模板下载") }}</a-button>
          <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
            <a-button icon="upload">{{ $t("期初库存导入") }}</a-button>
          </a-upload>
          <a-button @click="batchPrint">{{ $t("批量打印") }}</a-button>
        </a-button-group>
      </a-col>

      <div style="margin-bottom: 12px; float: right">
        <a-button type="primary" icon="plus" style="margin: 0 8px" @click="addStockCheckOrder">{{ $t("新增盘点单") }}</a-button>
      </div>
    </a-row>

    <div>
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="items"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="onChangeTable"
        :scroll="{ x: 1080 }"
      >
        <template slot="number" slot-scope="value, item, index">
          <a @click="detail(item)">{{ value }}</a>
        </template>
        <div slot="action" slot-scope="value, item, index">
          <a-button-group size="small">
            <a-button @click="detail(item)">{{ $t("详情") }}</a-button>
            <a-button type="primary" :disabled="item.is_void || item.is_completed" @click="check(item)">
              {{ $t("盘点") }}
            </a-button>
            <a-popconfirm :title="$t('确定作废吗?')" @confirm="voidItem(item)">
              <a-button type="danger">{{ $t("作废") }}</a-button>
            </a-popconfirm>
          </a-button-group>
        </div>
      </a-table>
    </div>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>
  </div>
</template>

<script>
import { exportExcel } from "@/utils/excel";
import { stockCheckOrdersExport } from "@/api/export";
import { stockCheckOrderOption, warehouseOption } from "@/api/option";
import { stockCheckOrderVoid, stockCheckOrderPrint } from "@/api/stockCheck";
import { columns } from "./columns";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import JsBarcode from "jsbarcode";
import { subBatchTemplate, subBatchOrderImport } from "@/api/import";
import lodopPrintStockCheckOrder from "@/views/printConfigs/printStockCheckOrder";

export default {
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1, page_size: 16 },
      loading: false,
      items: [],
      warehouseItems: [],
      selectedRowKeys: [],
      selectionRows: [],

      importLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    stockCheckPrintConfig() {
      return this.$store.state.user.stockCheckOrderPrintConfig;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      stockCheckOrderOption(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    voidItem(item) {
      stockCheckOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    check(item) {
      this.$router.push({ path: "/stock_check/stock_check_record_create", query: { stock_check_order: item.id } });
    },
    detail(item) {
      this.$router.push({ path: "/stock_check/stock_check_order_detail", query: { id: item.id } });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要导出的盘点单!"));
        return false;
      }
      stockCheckOrdersExport({ stock_check_orders: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, this.$t("盘点单列表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      subBatchTemplate().then((resp) => {
        exportExcel(resp, this.$t("期初库存导入模版"));
      });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      subBatchOrderImport(data)
        .then(() => {
          this.$message.success(this.$t("期初库存导入成功"));
          this.list();
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
    addStockCheckOrder() {
      this.$router.push({ path: "/stock_check/stock_check_order_create" });
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    batchPrint() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的盘点单!"));
        return false;
      }

      stockCheckOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        lodopPrintStockCheckOrder(data, this.stockCheckPrintConfig);

        //         const pageSize = 18;
        //         const LODOP = getLodop();
        //         LODOP.PRINT_INIT("");
        //         LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        //         for (let stockCheckOrderItem of data) {
        //           let canvas = document.createElement("canvas");
        //           JsBarcode(canvas, stockCheckOrderItem.number, {
        //             format: "CODE128",
        //             width: 2,
        //             height: 35,
        //             displayValue: true,
        //             background: "#ffffff",
        //             lineColor: "#000000",
        //           });
        //           let barcodeBase64 = canvas.toDataURL();

        //           stockCheckOrderItem.stock_check_material_items.sort((a, b) =>
        //             a.location_item.number.localeCompare(b.location_item.number)
        //           );

        //           let pages = Math.ceil(stockCheckOrderItem.stock_check_material_items.length / pageSize);
        //           for (let index = 1; index <= pages; index++) {
        //             let stockCheckMaterialItems = stockCheckOrderItem.stock_check_material_items.slice(
        //               (index - 1) * pageSize,
        //               index * pageSize
        //             );

        //             let tableRows = stockCheckMaterialItems.map((item) => {
        //               return `
        // <tr>
        //   <td>${this.printText(item.material_item.number)}</td>
        //   <td>${this.printText(item.material_item.name)}</td>
        //   <td>${this.printText(item.material_item.spec)}</td>
        //   <td>${this.printText(item.batch_item.number)}</td>
        //   <td>${this.printText(item.location_item.number)}</td>
        //   <td>${this.printText(item.book_quantity)}</td>
        //   <td>${this.printText(item.assigned_quantity)}</td>
        //   <td>${this.printText(item.assignable_quantity)}</td>
        //   <td></td>
        // </tr>`;
        //             });

        //             let pageContent = `
        // <div style="padding: 8px">
        //   <div style="font-size: 36px; text-align: center">
        //     <span>${this.$t("盘点单")}</span>
        //     <span style="float: right;">${pages > 1 ? index : ""}</span>
        //   </div>

        //   <div style="margin-top: 8px; text-align: right">
        //     <img src="${barcodeBase64}" />
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 33%">${this.$t("创建人")}: ${this.printText(stockCheckOrderItem.creator_item.name)}</div>
        //     <div style="width: 34%">${this.$t("创建时间")}: ${this.printText(stockCheckOrderItem.create_time)}</div>
        //     <div style="width: 33%">${this.$t("备注")}: ${this.printText(stockCheckOrderItem.remark)}</div>
        //   </div>

        //   <div style="margin-top: 16px; font-size: 14px">
        //     <table border="1" cellspacing="0" style="width: 100%; text-align: center">
        //       <tr>
        //         <th>${this.$t("产品编号")}</th>
        //         <th>${this.$t("产品名称")}</th>
        //         <th>${this.$t("规格")}</th>
        //         <th>${this.$t("批次编号")}</th>
        //         <th>${this.$t("库位编号")}</th>
        //         <th>${this.$t("账面数量")}</th>
        //         <th>${this.$t("已分配数量")}</th>
        //         <th>${this.$t("可分配数量")}</th>
        //         <th>${this.$t("实际数量")}</th>
        //       </tr>
        //       ${tableRows.join("")}
        //     </table>
        //   </div>

        //   <div style="margin-top: 24px; font-size: 14px; display: flex">
        //     <div style="width: 33%">${this.$t("盘点人")}:</div>
        //     <div style="width: 34%">${this.$t("复核人")}:</div>
        //     <div style="width: 33%">${this.$t("盘点日期")}:</div>
        //   </div>
        // </div>`;
        //             LODOP.NewPage();
        //             LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
        //           }
        //         }
        //         LODOP.PREVIEW();
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
